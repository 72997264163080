<template>
    <div>
        <van-search 
            v-model="searchCarModel" 
            show-action 
            placeholder="请输入标准车型名称" 
            @search="onSearch" 
            :style="{
                borderBottom: '1px solid #CCC!important',
                borderTop: '1px solid #CCC!important',
            }"
            background="#4fc08d"
        >
            <template #action>
                <div >
                    <span :style="{padding:'0px 10px',color:'#000',fontWeight:'bolder'}" @click="onSearch" >
                        搜索车型
                    </span>
                    <span :style="{padding:'0px 10px',color:'#fff'}" @click="$emit('closePopup')">关闭
                        <van-icon name="close" color="#fff" size="20px" :style="{fontWeight:'bolder'}"/>
                    </span>
                    
                </div>
            </template>
        </van-search>

        <div class="warper-list">

            <van-radio-group v-model="radioData">
                <div class="list" v-for="item in listData" :key="item.id">
                    <van-radio :name="item" :style="{padding:'1em',backgroundColor:radioData.id==item.id?'orange':'#fff'}">
                        <div :style="{marginLeft:'2em'}">{{item.name}}</div>
                    </van-radio>
                </div>
            </van-radio-group>

            <van-button v-if="listData.length>0" class="content-btn" type="info" size="large" @click="submit">确定</van-button>
        </div>
    </div>
</template>

<script>

import { Toast, Dialog } from 'vant'
export default {

    data() {
        return {
            imageFile: [],
            WXimg: [],
            username: "",
            password: '',
            toast: null,
            showPicker: false,
            wximagelocalids: [],
            columns: [],
            Name: "",
            islable: "",//判断上川的什么类型照片进行识别
            idsIndex: null,
            infoObj: [
                // {
                //     patrsName:"",//配件名称
                //     num:"",//配件数量
                //     color:"",//配件颜色
                //     carAccessoriesId:"",//配件分类
                //     five_big:"",//五大总成
                //     weight:"",//重量
                //     img_boxs:[],//照片
                //     imgs:[],
                // },
            ],
            searchCarModel: "",
            listData:[],
            radioData:"",
        }
    },
    watch: {
        wximagelocalids: {
            handler(newValue, oldValue) {
                if (newValue.length > 0) {
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    this.wxUpload(newValue[0])
                } else {
                    this.toast.clear();
                }
            },
            deep: true,
        }
    },
    methods: {
        submit(){
            let that=this
            let carmodel_id=this.radioData.id

            this.$http.post(
                "/index.php/car_model/car_model_waste_list",
                { id: carmodel_id }
            ).then((res) => {
                let data = res.data.data.data
                if (data.length == 0) {
                    Dialog.confirm({
                        title: '提示',
                        message: `没有找到标准危废拆解物，请重新检索`,
                        theme: 'round-button',
                    }).then(() => {

                    })
                        .catch(() => { });
                }else{
                    console.log("标准危废拆解物",data)
                    that.$emit('addWaste',data)
                }
            })
        },
        onSearch() {
            let that = this
            this.$http.post(
                "/index.php/car_model/list",
                { name: that.searchCarModel }
            ).then((res) => {
                let data = res.data.data.data
                if (data.length == 0) {
                    Dialog.confirm({
                        title: '提示',
                        message: `没有找到标准车型，请重新检索`,
                        theme: 'round-button',
                    }).then(() => {

                    })
                        .catch(() => { });
                }else{
                    that.listData=data
                }
            })
        },
        
    },
}
</script>

<style lang="scss" scoped>
.warper-tit {
    position: relative;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #eee;

    .van-icon {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}

.title {
    font-weight: bold;
    font-size: 22px;
    padding: 10px;
}

.forms {
    padding: 20px;
    position: relative;

    .van-icon {
        position: absolute;
        right: 10px;
        top: 0px;
    }
}

.isSubmit {
    margin-top: 1rem;
    text-align: center;

    .van-button-p {
        margin: 0 10px;
    }
}

.upimg {
    padding: 0 12px;

    .uploaderImg {
        display: inline-block;
        width: 100px;
        height: 100px;
        border: 1px solid #eee;
        position: relative;

        .iconz {
            position: absolute;
            top: 42%;
            left: 38%;

        }
    }
}

.vanImage {
    position: relative;
    margin-right: 10px;
    margin-top: 10px;

    .iconX {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
</style>